import { useRouteParams as _useRouteParams } from '@vueuse/router'
import type { ReactiveRouteOptions } from '#types/composables/router'

/**
 * Extends the original `useRouteParams` to use the Nuxt's route and router
 */
export function useRouteParams(name: string): Ref<null | string | string[]>
export function useRouteParams<T extends null | undefined | string | string[] = null | string | string[]>(
  name: string,
  defaultValue?: T,
  options?: ReactiveRouteOptions): Ref<T>
export function useRouteParams<T extends string | string[]>(
  name: string,
  defaultValue?: T,
  options: ReactiveRouteOptions = {}
) {
  return _useRouteParams(name, defaultValue?.toString(), {
    ...options,
    route: useRoute(),
    router: useRouter()
  })
}
